import React from "react";
import Navbar from "./Navbar";
import LandingPage from "./LandingPage";
import BottlePricePage from "./BottlePricePage";
import ContactUsPage from "./ContactUsPage";
import FaqPage from "./FaqPage";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import MobileAppHomePage from "./MobileAppHomePage";

function Home() {
  return (
    <div className="App">
      {/* SEO Meta Tags */}
      <Helmet>
        <title>PK Pani Wala - Premium Water Bottles Delivered to Your Doorstep</title>
        <meta
          name="description"
          content="PK Pani Wala offers premium bottled water delivered straight to your doorstep. Experience fresh, pure, and clean water for every home and business."
        />
        <meta
          name="keywords"
          content="PK Pani Wala, bottled water delivery, clean water, fresh water, premium water, doorstep water delivery, eco-friendly water bottles"
        />
        <link rel="canonical" href="https://www.pkpaniwala.com/" />
      </Helmet>

      {/* Page Components */}
      <Navbar />
      <LandingPage />
      <MobileAppHomePage />
      {/* <BottlePricePage /> */}
      <ContactUsPage />
      <FaqPage />
      <Footer />
    </div>
  );
}

export default Home;
