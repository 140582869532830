import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Helmet } from "react-helmet";

const faqData = [
  {
    question: "What are the payment options available?",
    answer:
      "We offer multiple payment options, including COD (Cash on Delivery) and Online Payment via Credit/Debit Cards, Net Banking, and UPI.",
  },
  {
    question: "How do I place an order?",
    answer:
      "Simply select the water bottle of your choice, add it to the cart, and proceed to checkout. You can choose COD or online payment for your order.",
  },
  {
    question: "Is home delivery available?",
    answer:
      "Yes, we offer home delivery for all our products within our service areas. Your order will be delivered to your doorstep within the specified time frame.",
  },
  {
    question: "What if I receive a damaged bottle?",
    answer:
      "If you receive a damaged product, please contact our customer support team within 24 hours. We'll arrange for a replacement or refund as per your request.",
  },
  {
    question: "Can I track my order?",
    answer:
      "Yes, once your order is dispatched, you will receive a tracking link via SMS and email. You can track the status of your delivery in real-time.",
  },
  {
    question: "Do you offer discounts or promotions?",
    answer:
      "We frequently offer discounts and promotions. Please sign up for our newsletter to receive updates on special offers and discounts.",
  },
];

const FaqPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ minHeight: "100vh", padding: "4rem 0" }}>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: faqData.map((item) => ({
              "@type": "Question",
              name: item.question,
              acceptedAnswer: {
                "@type": "Answer",
                text: item.answer,
              },
            })),
          })}
        </script>
      </Helmet>
      <Container maxWidth="lg">
        <Typography
          variant={isMobile ? "h5" : "h4"}
          sx={{
            fontWeight: 700,
            textAlign: "center",
            marginBottom: 4,
            color: "#2C3E50",
            textTransform: "uppercase",
            letterSpacing: "1px",
          }}
        >
          Frequently Asked Questions (FAQ)
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card
              sx={{
                borderRadius: "8px",
                padding: "2rem",
                backgroundColor: "#fff",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                border: "1px solid #ECECEC",
              }}
            >
              <Grid container spacing={2}>
                {faqData.map((faq, index) => (
                  <Grid item xs={12} key={index}>
                    <Accordion
                      sx={{
                        borderRadius: "8px",
                        marginBottom: "0rem",
                        boxShadow: "none",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon sx={{ color: "#3498DB" }} />
                        }
                        sx={{
                          backgroundColor: "#F9F9F9",
                          borderRadius: "8px",
                          padding: "1rem",
                          "&:hover": {
                            backgroundColor: "#F1F1F1",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            color: "#2C3E50",
                            fontSize: "1.1rem",
                          }}
                        >
                          {faq.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            color: "#7F8C8D",
                            fontSize: "1rem",
                            lineHeight: "1.6",
                            textAlign: isMobile ? "justify" : "left",
                          }}
                        >
                          {faq.answer}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FaqPage;
