import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import PolicyIcon from "@mui/icons-material/Policy";
import ContactsIcon from "@mui/icons-material/Contacts";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import Logo from "../Images/logo192.png"; // Replace with your logo path
import googleplayestore from "../Images/google-playe-store.png";

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        backgroundColor: "#1e1e1e",
        color: "white",
        padding: "2rem 0", // Reduced padding for better mobile view
        position: "relative",
        bottom: 0,
        width: "100%",
        borderTop: "1px solid #444",
        fontFamily: "'Roboto', sans-serif",
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          {/* Left Section - Logo and Description */}
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{ textAlign: isMobile ? "center" : "left" }}
          >
            <Link to={"/"}>
              <Box
                component="img"
                src={Logo}
                alt="Logo"
                sx={{
                  width: isMobile ? "100px" : "150px",
                  marginBottom: "1rem",
                }}
              />
            </Link>

            <Typography
              variant="body2"
              sx={{
                opacity: 0.7,
                fontSize: isMobile ? "0.75rem" : "1rem",
                lineHeight: 1.5,
              }}
            >
              Premium Water Bottles Delivered to Your Doorstep. Fresh and Pure
              Water for Every Home.
            </Typography>
          </Grid>

          {/* Center Section - Quick Links */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography
              variant="h6"
              sx={{
                marginBottom: "1rem",
                fontWeight: 600,
                letterSpacing: "0.5px",
                fontSize: isMobile ? "1rem" : "1.25rem",
              }}
            >
              Quick Links
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Link
                  href="/"
                  color="inherit"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    fontSize: isMobile ? "0.8rem" : "1rem",
                    transition: "color 0.3s ease",
                    "&:hover": { color: "#ff4081" },
                  }}
                >
                  <HomeIcon sx={{ fontSize: "1.2rem" }} /> Home
                </Link>
              </Grid>
              <Grid item xs={6}>
                <Link
                  href="/about-us"
                  color="inherit"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    fontSize: isMobile ? "0.8rem" : "1rem",
                    transition: "color 0.3s ease",
                    "&:hover": { color: "#ff4081" },
                  }}
                >
                  <InfoIcon sx={{ fontSize: "1.2rem" }} /> About
                </Link>
              </Grid>
              <Grid item xs={6}>
                <Link
                  href="/privacy-policy"
                  color="inherit"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    fontSize: isMobile ? "0.8rem" : "1rem",
                    transition: "color 0.3s ease",
                    "&:hover": { color: "#ff4081" },
                  }}
                >
                  <PrivacyTipIcon sx={{ fontSize: "1.2rem" }} /> Privacy Policy
                </Link>
              </Grid>
              <Grid item xs={6}>
                <Link
                  href="/terms-and-conditions"
                  color="inherit"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    fontSize: isMobile ? "0.8rem" : "1rem",
                    transition: "color 0.3s ease",
                    "&:hover": { color: "#ff4081" },
                  }}
                >
                  <PolicyIcon sx={{ fontSize: "1.2rem" }} /> Terms & Conditions
                </Link>
              </Grid>
              <Grid item xs={6}>
                <Link
                  href="/contact-us"
                  color="inherit"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    fontSize: isMobile ? "0.8rem" : "1rem",
                    transition: "color 0.3s ease",
                    "&:hover": { color: "#ff4081" },
                  }}
                >
                  <ContactsIcon sx={{ fontSize: "1.2rem" }} /> Contact Us
                </Link>
              </Grid>
            </Grid>
          </Grid>

          {/* Right Section - Follow Us and Download App */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography
              variant="h6"
              sx={{
                marginBottom: "1rem",
                fontWeight: 600,
                letterSpacing: "0.5px",
                fontSize: isMobile ? "1rem" : "1.25rem",
              }}
            >
              Follow Us
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <IconButton
                href="https://www.facebook.com"
                target="_blank"
                sx={{ color: "white", "&:hover": { color: "#ff4081" } }}
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                href="https://www.instagram.com"
                target="_blank"
                sx={{ color: "white", "&:hover": { color: "#ff4081" } }}
              >
                <InstagramIcon />
              </IconButton>
              <IconButton
                href="https://www.twitter.com"
                target="_blank"
                sx={{ color: "white", "&:hover": { color: "#ff4081" } }}
              >
                <TwitterIcon />
              </IconButton>
            </Box>
            <Typography
              variant="h6"
              sx={{
                marginTop: "1.5rem",
                fontWeight: 600,
                letterSpacing: "0.5px",
                fontSize: isMobile ? "1rem" : "1.25rem",
              }}
            >
              Download The App On
            </Typography>
            <Box sx={{ marginTop: "1rem" }}>
              <Link
                href="https://play.google.com/store"
                target="_blank"
                sx={{
                  display: "inline-block",
                  width: isMobile ? "120px" : "160px",
                  height: isMobile ? "40px" : "50px",
                  background: `url(${googleplayestore}) no-repeat center center`,
                  backgroundSize: "contain",
                }}
              />
            </Box>
          </Grid>
        </Grid>

        {/* Bottom Section - Copyright */}
        <Box sx={{ textAlign: "center", marginTop: "3rem", opacity: 0.7 }}>
          <Typography
            variant="body2"
            sx={{ fontSize: isMobile ? "0.8rem" : "0.9rem" }}
          >
            &copy; {new Date().getFullYear()} PK Pani Wala. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
