import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ContactUsPage from "./ContactUsPage";
import { Helmet } from "react-helmet";

function ContactUs() {
  return (
    <div style={{backgroundColor:"#D3D3D3"}}>
      <Helmet>
        <title>Contact PK Pani Wala - Get in Touch with Us</title>
        <meta
          name="description"
          content="Have questions or need assistance? Contact PK Pani Wala for premium water bottle delivery services. We're here to help you stay hydrated!"
        />
        <meta
          name="keywords"
          content="Contact PK Pani Wala, customer support, water delivery inquiries, contact bottled water supplier, get in touch, premium water bottles"
        />
        <link rel="canonical" href="https://www.pkpaniwala.com/contact-us" />
      </Helmet>
      <Navbar  />
      <ContactUsPage />
      <br/>
      <Footer />
    </div>
  );
}

export default ContactUs;
