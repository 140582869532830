import React from "react";
import { Button, Container, Grid, Typography, Box } from "@mui/material";
import mobileAppHomepage from "../Images/mobile-app-home-page.webp"; // Ensure the path is correct

const MobileAppHomePage = () => {
  const handleDownloadClick = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.example.app"; // Replace with your app's Play Store URL
  };

  return (
    <Box
      sx={{
        padding: { xs: "20px", md: "40px" },
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage:
          "url(https://media.istockphoto.com/id/1481610068/photo/cold-bottle-of-water-over-ice-cubes-food-and-drink-background.jpg?s=612x612&w=0&k=20&c=S1Jegi_CzVAQGctFoLcgspEenne1GzWg8sSRS8ww5qQ=)", // Replace with your background image URL
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        boxShadow: "inset 0px 0px 100px rgba(0, 0, 0, 0.3)",
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={6}
          alignItems="center"
          justifyContent="center"
          sx={{
            flexDirection: { xs: "column", md: "row" },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          {/* Left side - Image */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                position: "relative",
                height: { xs: "400px", sm: "400px", md: "500px" },
                width: { xs: "230px", sm: "230px", md: "280px" },
                borderRadius: "16px",
                boxShadow: "rgba(0, 0, 0, 0.4) 0px 30px 90px",
                overflow: "hidden",
                mx: { xs: "auto", md: 0 },
              }}
            >
              <img
                src={mobileAppHomepage}
                alt="Mobile App"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  transition: "transform 0.3s ease",
                }}
              />
            </Box>
          </Grid>

          {/* Right side - Text and Button */}
          <Grid item xs={12} md={6}>
            <Box>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: "bold",
                  color: "black",
                  marginBottom: "20px",
                  fontSize: { xs: "1.8rem", sm: "2rem", md: "2.5rem" },
                  lineHeight: "1.2",
                  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                }}
              >
                Get the Best App Now!
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "black",
                  marginBottom: "30px",
                  fontSize: { xs: "0.9rem", sm: "1rem", md: "1.125rem" },
                  lineHeight: "1.6",
                  maxWidth: { xs: "100%", sm: "80%", md: "600px" },
                  mx: { xs: "auto", md: 0 },
                }}
              >
                Download the app now and explore amazing features designed to
                enhance your experience. Stay connected, stay productive, and
                unlock endless possibilities.
              </Typography>
              <Button
                variant="contained"
                onClick={handleDownloadClick}
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  padding: { xs: "10px 20px", sm: "12px 30px" },
                  borderRadius: "30px",
                  fontWeight: "bold",
                  fontSize: { xs: "0.9rem", sm: "1rem" },
                  transition: "background-color 0.3s ease, transform 0.3s ease",
                  "&:hover": {
                    backgroundColor: "primary.dark",
                    transform: "scale(1.05)",
                  },
                }}
              >
                Download on Google Play
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default MobileAppHomePage;
