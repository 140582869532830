import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { keyframes } from "@mui/system";
import backgroundImage from "../Images/bg.jpg"; // Replace with your background image
import waterBottle from "../Images/water-bottle.png"; // Replace with your water bottle image

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Keyframe animation for water bottle image
const moveUpDown = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
`;

const LandingPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleContactClick  = () => {
    const phoneNumber = "+1234567890"; // Replace with your WhatsApp number
    const message = encodeURIComponent(
      "Hello, I would like to inquire about your premium water bottle delivery service."
    );
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappUrl, "_blank");
  };


  return (
    <Box
      sx={{
        position: "relative",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        color: "white",
        textAlign: "center",
      }}
    >
      {/* Overlay for better text visibility */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          zIndex: 1,
        }}
      />

      {/* Main Content */}
      <Container
        maxWidth="lg"
        sx={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <Grid
          container
          spacing={4}
          sx={{
            alignItems: "center",
          }}
        >
          {/* Left Section - Text */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              textAlign: isMobile ? "center" : "left",
              animation: `${fadeIn} 1.5s ease-in-out`, // Applying the infinite animation
            }}
          >
            <Typography
              variant={isMobile ? "h4" : "h2"}
              sx={{
                fontWeight: "bold",
                mb: isMobile ? 2 : 3,
              }}
            >
              Stay Hydrated with PK Pani Wala
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mb: 4,
                color: "rgba(255, 255, 255, 0.9)",
                fontSize: isMobile ? "1rem" : "1.25rem",
              }}
            >
              Experience the perfect blend of style and functionality with our
              premium water bottles. Keep your water cold and your style cool!
            </Typography>
            <Button
              variant="contained"
              size="large"
              onClick={handleContactClick}
              sx={{
                backgroundColor: "#00bcd4",
                "&:hover": { backgroundColor: "#0097a7" },
                padding: "12px 24px",
                fontSize: "1.2rem",
              }}
            >
              Contact Now
            </Button>
          </Grid>

          {/* Right Section - Image */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              textAlign: "center",
            }}
          >
            <Box
              component="img"
              src={waterBottle}
              alt="Premium Water Bottle"
              sx={{
                width: isMobile ? "80%" : "60%",
                maxHeight: "400px",
                objectFit: "contain",
                borderRadius: "10px",
                animation: `${moveUpDown} 3s ease-in-out infinite`, // Applying the infinite animation
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LandingPage;
