import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import waterBottle from "../Images/water-bottle.png";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <Box>
      {/* SEO Meta Tags */}
      <Helmet>
        <title>
          About Us - PK Pani Wala | Your Trusted Water Bottle Delivery Partner
        </title>
        <meta
          name="description"
          content="Learn more about PK Pani Wala, your trusted partner for premium bottled water delivery. Discover our mission, values, and commitment to providing fresh, clean water to every doorstep."
        />
        <meta
          name="keywords"
          content="About PK Pani Wala, water delivery service, clean water mission, premium water bottles, eco-friendly water, trusted water delivery partner"
        />
        <link rel="canonical" href="https://www.pkpaniwala.com/about-us" />
      </Helmet>

      <Navbar />
      <Box
        sx={{
          backgroundColor: "#D3D3D3",
          color: "#333",
          minHeight: "100vh",
          padding: "6rem 0",
          fontFamily: "'Roboto', sans-serif",
        }}
      >
        <Container maxWidth="lg">
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              marginBottom: "1.5rem",
              textAlign: "center",
            }}
          >
            About Us
          </Typography>

          <Typography
            variant="body1"
            sx={{
              marginBottom: "2rem",
              lineHeight: 1.8,
              textAlign: "center",
            }}
          >
            Welcome to PK Pani Wala, your trusted partner for delivering premium
            bottled water to your doorstep. Our mission is to ensure every home
            and business has access to fresh, pure, and clean drinking water.
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <img
                src={waterBottle}
                alt="About Us"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "10px",
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  marginBottom: "1rem",
                }}
              >
                Our Story
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  marginBottom: "1.5rem",
                  lineHeight: 1.8,
                }}
              >
                PK Pani Wala started with a vision to solve the problem of clean
                drinking water availability. We understand the importance of
                hydration and strive to deliver only the best water to our
                customers.
              </Typography>

              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  marginBottom: "1rem",
                }}
              >
                Our Commitment
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  marginBottom: "1.5rem",
                  lineHeight: 1.8,
                }}
              >
                We are committed to maintaining the highest standards of
                quality, hygiene, and sustainability. Our bottles are packaged
                in an eco-friendly way, ensuring minimal impact on the
                environment.
              </Typography>
            </Grid>
          </Grid>

          <Box
            sx={{
              marginTop: "3rem",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                marginBottom: "1rem",
              }}
            >
              Why Choose Us?
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginBottom: "1.5rem",
                lineHeight: 1.8,
                maxWidth: "800px",
                margin: "0 auto",
              }}
            >
              At PK Pani Wala, we believe that water is life. That’s why we go
              the extra mile to ensure you receive the purest and freshest water
              with every delivery. With timely deliveries, competitive pricing,
              and a focus on customer satisfaction, we are the preferred choice
              for thousands of households and businesses.
            </Typography>
          </Box>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default AboutUs;
