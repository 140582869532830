import React from "react";
import { Box, Typography, Button } from "@mui/material";
import ContactImage from "../Images/contact-us.png"; // Replace with your image path

function ContactUsPage() {
  const handleWhatsAppClick = () => {
    const phoneNumber = "+1234567890"; // Replace with your WhatsApp number
    const message = encodeURIComponent(
      "Hello, I would like to inquire about your premium water bottle delivery service."
    );
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: { xs: "column", md: "row" },
          backgroundColor: "#D3D3D3",
          padding: { xs: "20px", md: "40px" },
          gap: { xs: "20px", md: "40px" },
        }}
      >
        {/* Left - Image */}
        <Box
          sx={{
            maxWidth: "500px",
            width: "100%",
            borderRadius: "16px",
            overflow: "hidden",
          }}
        >
          <img
            src={ContactImage}
            alt="Contact Us"
            style={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
            }}
          />
        </Box>

        {/* Right - Text and Button */}
        <Box
          sx={{
            maxWidth: "500px",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              marginBottom: "20px",
              color: "#333",
            }}
          >
            Get in Touch with Us
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#666",
              marginBottom: "30px",
              fontSize: { xs: "1rem", md: "1.125rem" },
              lineHeight: "1.6",
            }}
          >
            Have questions or need assistance? Contact PK Pani Wala for premium
            water bottle delivery services. We're here to help you stay
            hydrated and answer any inquiries you may have.
          </Typography>
          <Button
            variant="contained"
            onClick={handleWhatsAppClick}
            sx={{
              backgroundColor: "#25D366",
              color: "white",
              padding: "12px 30px",
              borderRadius: "30px",
              fontWeight: "bold",
              fontSize: "1rem",
              transition: "background-color 0.3s ease, transform 0.3s ease",
              "&:hover": {
                backgroundColor: "#20b557",
                transform: "scale(1.05)",
              },
            }}
          >
            Message Us on WhatsApp
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default ContactUsPage;
