import React from "react";
import { Box, Container, Typography, Link } from "@mui/material";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const TermsAndConditions = () => {
  return (
    <Box>
      <Helmet>
        <title>Terms and Conditions - PK Pani Wala</title>
        <meta
          name="description"
          content="Read the terms and conditions of PK Pani Wala. Learn about our policies, user agreements, and important guidelines for using our water delivery services."
        />
        <meta
          name="keywords"
          content="Terms and Conditions, PK Pani Wala policies, user agreement, service guidelines, water delivery terms"
        />
        <link
          rel="canonical"
          href="https://www.pkpaniwala.com/terms-and-conditions"
        />
      </Helmet>
      <Navbar />
      <Box
        sx={{
          backgroundColor: "#D3D3D3",
          minHeight: "100vh",
          padding: "6rem 0",
        }}
      >
        <Container maxWidth="md">
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              marginBottom: "1.5rem",
              textAlign: "center",
            }}
          >
            Terms and Conditions
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginBottom: "1.5rem",
              lineHeight: 1.8,
            }}
          >
            Welcome to PK Pani Wala! By using our website and purchasing our
            products, you agree to comply with the following terms and
            conditions. Please read them carefully before placing an order.
          </Typography>

          <Typography
            variant="h6"
            sx={{ fontWeight: 600, marginBottom: "0.5rem" }}
          >
            1. General Terms
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: "1.5rem", lineHeight: 1.8 }}
          >
            a. These terms and conditions govern the use of our website and the
            purchase of our water bottles. <br />
            b. By accessing our website, you agree to be bound by these terms
            and conditions. <br />
            c. We reserve the right to update or change these terms at any time
            without prior notice.
          </Typography>

          <Typography
            variant="h6"
            sx={{ fontWeight: 600, marginBottom: "0.5rem" }}
          >
            2. Ordering
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: "1.5rem", lineHeight: 1.8 }}
          >
            a. All orders placed on PK Pani Wala are subject to availability and
            confirmation of payment. <br />
            b. We reserve the right to cancel or refuse any order at our sole
            discretion. <br />
            c. Once your order is confirmed, you will receive a confirmation
            email with order details.
          </Typography>

          <Typography
            variant="h6"
            sx={{ fontWeight: 600, marginBottom: "0.5rem" }}
          >
            3. Payment
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: "1.5rem", lineHeight: 1.8 }}
          >
            a. We accept online payment methods, including credit/debit cards
            and UPI. <br />
            b. All payments are securely processed, and no card details are
            stored on our servers.
          </Typography>

          <Typography
            variant="h6"
            sx={{ fontWeight: 600, marginBottom: "0.5rem" }}
          >
            4. Delivery
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: "1.5rem", lineHeight: 1.8 }}
          >
            a. We aim to deliver your water bottles within the estimated time
            frame mentioned during checkout. <br />
            b. Delivery delays due to unforeseen circumstances will be
            communicated promptly. <br />
            c. Delivery charges may apply, depending on your location.
          </Typography>

          <Typography
            variant="h6"
            sx={{ fontWeight: 600, marginBottom: "0.5rem" }}
          >
            5. Returns and Refunds
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: "1.5rem", lineHeight: 1.8 }}
          >
            a. If you receive a defective or damaged product, please contact us
            within 7 days for a replacement or refund. <br />
            b. Refunds will be processed to the original payment method within
            5-7 business days. <br />
            c. Returned products must be in their original packaging and unused.
          </Typography>

          <Typography
            variant="h6"
            sx={{ fontWeight: 600, marginBottom: "0.5rem" }}
          >
            6. Intellectual Property
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: "1.5rem", lineHeight: 1.8 }}
          >
            a. All content on this website, including logos, images, and text,
            is the intellectual property of PK Pani Wala. <br />
            b. Unauthorized use, reproduction, or distribution of any content is
            prohibited.
          </Typography>

          <Typography
            variant="h6"
            sx={{ fontWeight: 600, marginBottom: "0.5rem" }}
          >
            7. Limitation of Liability
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: "1.5rem", lineHeight: 1.8 }}
          >
            a. PK Pani Wala shall not be liable for any indirect, incidental, or
            consequential damages arising from the use of our website or
            products. <br />
            b. Our maximum liability shall not exceed the amount paid for your
            order.
          </Typography>

          <Typography
            variant="h6"
            sx={{ fontWeight: 600, marginBottom: "0.5rem" }}
          >
            8. Contact Us
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: "1.5rem", lineHeight: 1.8 }}
          >
            If you have any questions or concerns regarding these terms and
            conditions, please contact us at:
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: "2rem", lineHeight: 1.8 }}
          >
            Email:{" "}
            <Link href="mailto:support@pkpaniwala.com">
              support@pkpaniwala.com
            </Link>{" "}
            <br />
            Phone: +91-9876543210
          </Typography>

          <Typography
            variant="body2"
            sx={{ textAlign: "center", marginTop: "2rem", opacity: 0.7 }}
          >
            &copy; {new Date().getFullYear()} PK Pani Wala. All rights reserved.
          </Typography>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default TermsAndConditions;
