import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import logo from "../Images/logo192.png";
import { Link } from "react-router-dom";

const Navbar = ({color}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [bgColor, setBgColor] = useState("transparent");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Handle scroll effect to change background color
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setBgColor("#f5f5f5"); // Light grey when scrolled
      } else {
        setBgColor("transparent"); // Transparent at the top
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const navItems = [
    { label: "Home", link: "/" },
    { label: "Privacy", link: "/privacy-policy" },
    { label: "Contact Us", link: "/contact-us" },
    { label: "Terms and Conditions", link: "/terms-and-conditions" },
  ];

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: bgColor,
        boxShadow: "none",
        padding: isMobile ? "0 16px" : "0 32px",
        transition: "background-color 0.3s ease-in-out",
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* Logo */}
        <Link to={"/"}>
          <img
            src={logo}
            alt="Logo"
            style={{ height: 50, cursor: "pointer" }}
          />
        </Link>

        {/* Desktop Menu */}
        {!isMobile && (
          <Box sx={{ display: "flex", gap: 2 }}>
            {navItems.map((item, index) => (
              <Button
                key={index}
                href={item.link}
                sx={{
                  color: bgColor === "transparent" ? "white" : "black",
                  fontWeight: "bold",
                  textTransform: "none",
                  "&:hover": { color: "#ff5722" },
                }}
              >
                {item.label}
              </Button>
            ))}
          </Box>
        )}

        {/* Mobile Menu */}
        {isMobile && (
          <>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuClick}
              sx={{
                color: bgColor === "transparent" ? "white" : "black",
              }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              sx={{
                "& .MuiPaper-root": { backgroundColor: "#333", color: "white" },
              }}
            >
              {navItems.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={handleMenuClose}
                  component="a"
                  href={item.link}
                  sx={{ "&:hover": { backgroundColor: "#444" } }}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
