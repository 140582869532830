import React from "react";
import {
  Box,
  Container,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import PolicyIcon from "@mui/icons-material/Policy";
import SecurityIcon from "@mui/icons-material/Security";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <Box>
      <Helmet>
        <title>Privacy Policy - PK Pani Wala</title>
        <meta
          name="description"
          content="Read the privacy policy of PK Pani Wala. Learn about how we collect, use, and protect your personal information when you use our bottled water delivery service."
        />
        <meta
          name="keywords"
          content="Privacy Policy, PK Pani Wala, personal information, data protection, user privacy, water delivery service, online water bottles"
        />
        <link
          rel="canonical"
          href="https://www.pkpaniwala.com/privacy-policy"
        />
      </Helmet>
      <Navbar />
      <Box
        sx={{
          backgroundColor: "#D3D3D3",
          minHeight: "100vh",
          padding: "6rem 0",
        }}
      >
        <Container maxWidth="md">
          {/* Header Section */}
          <Paper
            elevation={3}
            sx={{
              padding: "2rem",
              marginBottom: "2rem",
              backgroundColor: "#ffffff",
              borderRadius: "12px",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontWeight: "bold", color: "#1e88e5" }}
            >
              Privacy Policy
            </Typography>
            <Typography variant="body1" sx={{ opacity: 0.8, fontSize: "1rem" }}>
              Your privacy is important to us. This Privacy Policy explains how
              we collect, use, and safeguard your information when you use our
              services.
            </Typography>
          </Paper>

          {/* Privacy Policy Content */}
          <Paper
            elevation={1}
            sx={{
              padding: "2rem",
              backgroundColor: "#ffffff",
              borderRadius: "12px",
            }}
          >
            {/* Introduction Section */}
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontWeight: "bold", marginBottom: "1rem" }}
            >
              1. Information We Collect
            </Typography>
            <Typography
              variant="body2"
              sx={{ marginBottom: "1rem", lineHeight: 1.6 }}
            >
              We may collect personal information such as your name, email
              address, delivery address, and phone number when you create an
              account or place an order. Additionally, we collect non-personal
              information like your browser type and IP address for analytical
              purposes.
            </Typography>
            <Divider sx={{ marginBottom: "1rem" }} />

            {/* How We Use Information Section */}
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontWeight: "bold", marginBottom: "1rem" }}
            >
              2. How We Use Your Information
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <PolicyIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="To process your orders and deliver products to your doorstep." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <PrivacyTipIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="To send you promotional offers and updates about new products." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <SecurityIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="To improve our website functionality and enhance user experience." />
              </ListItem>
            </List>
            <Divider sx={{ marginBottom: "1rem" }} />

            {/* Security Section */}
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontWeight: "bold", marginBottom: "1rem" }}
            >
              3. Security of Your Information
            </Typography>
            <Typography
              variant="body2"
              sx={{ marginBottom: "1rem", lineHeight: 1.6 }}
            >
              We implement robust security measures to protect your information
              from unauthorized access, use, or disclosure. However, please note
              that no data transmission or storage system can be 100% secure.
            </Typography>
            <Divider sx={{ marginBottom: "1rem" }} />

            {/* Third-Party Services Section */}
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontWeight: "bold", marginBottom: "1rem" }}
            >
              4. Third-Party Services
            </Typography>
            <Typography
              variant="body2"
              sx={{ marginBottom: "1rem", lineHeight: 1.6 }}
            >
              We may share your information with trusted third-party services to
              process payments or deliver orders. These services are
              contractually obligated to protect your information.
            </Typography>
            <Divider sx={{ marginBottom: "1rem" }} />

            {/* Your Rights Section */}
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontWeight: "bold", marginBottom: "1rem" }}
            >
              5. Your Rights
            </Typography>
            <Typography
              variant="body2"
              sx={{ marginBottom: "1rem", lineHeight: 1.6 }}
            >
              You have the right to access, update, or delete your personal
              information. If you wish to exercise these rights, please contact
              us at support@pkpaniwala.com.
            </Typography>
            <Divider sx={{ marginBottom: "1rem" }} />

            {/* Updates to Privacy Policy Section */}
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontWeight: "bold", marginBottom: "1rem" }}
            >
              6. Changes to This Privacy Policy
            </Typography>
            <Typography
              variant="body2"
              sx={{ marginBottom: "1rem", lineHeight: 1.6 }}
            >
              We may update this Privacy Policy from time to time. Any changes
              will be posted on this page, and the effective date will be
              updated.
            </Typography>
          </Paper>

          {/* Contact Section */}
          <Paper
            elevation={2}
            sx={{
              padding: "1.5rem",
              marginTop: "2rem",
              backgroundColor: "#e3f2fd",
              borderRadius: "12px",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                color: "#1e88e5",
                marginBottom: "0.5rem",
              }}
            >
              Have Questions?
            </Typography>
            <Typography
              variant="body2"
              sx={{ opacity: 0.8, fontSize: "1rem", marginBottom: "1rem" }}
            >
              Contact us at{" "}
              <a href="mailto:support@pkpaniwala.com">support@pkpaniwala.com</a>{" "}
              for any questions or concerns regarding this policy.
            </Typography>
          </Paper>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default PrivacyPolicy;
